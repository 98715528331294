import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import t from '@jetshop/intl';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconCart } from '../../svg/IconCart.svg';
import { ReactComponent as IconCross } from '../../svg/IconCross.svg';
import cartQuery from '../Cart/CartQuery.gql';
import removeFromCartMutation from '../Cart/removeFromCart.gql';
import { theme } from '../Theme';
import PriceFormatter from '../ui/PriceFormatter';
import AddAllToCart from './AddAllToCart';
import useFavourites from './useFavourites';

const ProductDetailWrapper = styled('section')`
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 50%;

  > :first-of-type {
    flex: 0 1 34%;

    ${theme.below.md} {
      max-width: 124px;
      min-width: 124px;
    }
  }

  > :last-of-type {
    flex: 0 1 66%;

    ${theme.below.md} {
      flex: calc(100% - 124px);
    }
  }
`;

const QtyDetails = styled('section')`
  display: flex;
  flex: 0 1 27%;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-top: -4px;
  }

  ${theme.below.md} {
    &[data-in-cart='true'] {
      justify-content: flex-start;
      svg {
        margin-left: ${theme.space[2]};
      }
    }
  }

  button:hover {
    background: none;
  }
`;

const RemoveItem = styled('div')`
  button {
    padding: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    outline: none;
    border: none;
    background: none;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    stroke: ${theme.colors.darkgrey};
    height: 10px;
    width: 10px;
  }
`;

const RemoveItemMobile = styled('div')`
  position: absolute;
  top: 0;
  left: 0;

  button {
    padding: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    outline: none;
    border: none;
    background: ${theme.colors.primary};
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    stroke: ${theme.colors.white};
    height: 12px;
    width: 12px;
  }
`;

const ItemDetails = styled('div')`
  a {
    text-decoration: none;
  }
`;

const ItemDetailsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
`;

const BrandName = styled('h2')`
  font-weight: ${theme.fontWeights.book};
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  color: ${theme.colors.mediumgrey};
`;

const ProductName = styled('h3')`
  font-weight: ${theme.fontWeights.medium};
  font-size: 1rem;
  color: ${theme.colors.primary};
  padding: ${theme.space[1]} 0;
`;

const ProductDetail = styled('div')`
  width: 100%;
  padding-left: 1.1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const LinePrice = styled(Price)`
  display: flex;
  flex-direction: column;

  .new-price {
    order: 2;
  }

  .old-price {
    order: 1;

    ${theme.below.md} {
      padding-left: 0;
    }
  }
`;

const StyledImg = styled(Image)`
  margin-right: 1rem;
  [data-flight-image-container] {
    height: 100%;
  }
`;

const ImgWrapper = styled('div')`
  max-height: 92px;
  width: 5rem;
`;

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background: white;
  width: 100%;

  ${theme.below.md} {
    flex-direction: column;
  }
`;

const FavouritesItem = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${theme.space[3]};
  background: white;
  border-bottom: 1px solid ${theme.colors.grey};

  &[data-in-cart='true'] {
    background: #fbfbfb;

    > div {
      background: #fbfbfb;
    }
  }
`;

const CartAcitons = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > *:first-of-type {
    color: ${theme.colors.mediumgrey};
    font-size: 0.85rem;
    margin-bottom: ${theme.space[2]};
  }

  ${theme.below.md} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > *:first-of-type {
      order: 2;
      margin: 0;
    }
  }
`;

const Cover = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${theme.colors.white};
  opacity: 0.6;
`;

const FavouritesFlyoutItem = ({
  product,
  drawer,
  inCart,
  setCartItems,
  onCompleted
}) => {
  const { toggleFavourite } = useFavourites();

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const [itemAdded, setItemAdded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const getCartItems = items => {
    setCartItems(items);

    const itemAdded = items.find(
      item => item.articleNumber === product.articleNumber
    );

    setItemAdded(itemAdded);
  };

  const removeItem = (inCart, itemAdded) => {
    setIsLoading(true);
    removeFromCart({
      itemId: inCart ? inCart.id : itemAdded.id,
      product: inCart ? inCart.product : itemAdded.product
    }).then(({ data }) => {
      setCartItems(data.removeFromCart.cart.items);
      setItemAdded(false);
      setIsLoading(false);
    });
  };

  return (
    <FavouritesItem data-in-cart={inCart}>
      <Wrapper key={product.id}>
        <ProductDetailWrapper>
          <div>
            <ImgWrapper key={product.images[0]?.url}>
              <StyledImg
                src={product.images[0]?.url}
                aspect={null}
                alt={product.images[0]?.url}
                quality={80}
                key={product.images[0]?.url}
                sizes={[1 / 4, 1 / 6, 1 / 8, 1 / 12]}
              />
            </ImgWrapper>
          </div>
          <Below breakpoint="md">
            {matches =>
              matches &&
              !inCart && (
                <RemoveItemMobile>
                  <button onClick={() => toggleFavourite(product)}>
                    <IconCross />
                  </button>
                </RemoveItemMobile>
              )
            }
          </Below>
          <ProductDetail>
            <ItemDetailsWrapper>
              <ItemDetails>
                <Link
                  to={product.primaryRoute.path}
                  onClick={drawer.hideTarget}
                >
                  <BrandName>{product.subName}</BrandName>
                  <ProductName>{product.name}</ProductName>
                </Link>
              </ItemDetails>
            </ItemDetailsWrapper>
          </ProductDetail>
        </ProductDetailWrapper>

        <QtyDetails data-in-cart={inCart}>
          {itemAdded.articleNumber === product.articleNumber || inCart ? (
            <div className={CartAcitons}>
              <span
                onClick={() => {
                  removeItem(inCart, itemAdded);
                }}
              >
                {t('Cansel')}
              </span>
              <span>{t('Added to cart')}</span>
            </div>
          ) : (
            <>
              <AddAllToCart
                items={[product]}
                title={true}
                getCartItems={getCartItems}
                isLoading={setIsLoading}
                onCompleted={onCompleted}
              >
                <IconCart />
              </AddAllToCart>
              <LinePrice
                previousPrice={product.previousPrice}
                price={product.price}
                formatter={PriceFormatter}
              />
              <Above breakpoint="md">
                {matches =>
                  matches && (
                    <RemoveItem>
                      <button onClick={() => toggleFavourite(product)}>
                        <IconCross />
                      </button>
                    </RemoveItem>
                  )
                }
              </Above>
            </>
          )}
        </QtyDetails>
      </Wrapper>
      {isLoading && <Cover />}
    </FavouritesItem>
  );
};

export default FavouritesFlyoutItem;
